export default {
    "/check": {
        show_in_menu: false,
        render_in_route: true
    },
    // "/group/:id/dashboard": {
    //     show_in_menu: false,
    //     render_in_route: true
    // },
    // "/groups/:id/card/:page/:tab": {
    //     show_in_menu: false,
    //     render_in_route: true
    // }
}