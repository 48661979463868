export default {
    // "/diagnostics": {
    //     show_in_menu: true,
    //     render_in_route: true
    // },
    // "/specialists": {
    //     show_in_menu: true,
    //     render_in_route: true
    // },
    // "/contracts/:id": {
    //     show_in_menu: false,
    //     render_in_route: true
    // },
    // "/contracts/:id/update": {
    //     show_in_menu: false,
    //     render_in_route: true
    // },
    // "/contracts/create": {
    //     show_in_menu: false,
    //     render_in_route: true
    // },
    // "/children/:id/dashboard": {
    //     show_in_menu: false,
    //     render_in_route: true
    // },
    // "/children/:id/card/:page/:tab": {
    //     show_in_menu: false,
    //     render_in_route: true
    // },
    // "/group/:id/dashboard": {
    //     show_in_menu: false,
    //     render_in_route: true
    // },
    // "/groups/:id/card/:page/:tab": {
    //     show_in_menu: false,
    //     render_in_route: true
    // },
    // "/groups/create": {
    //     show_in_menu: false,
    //     render_in_route: true
    // },
    // "/specialists/:id": {
    //     show_in_menu: false,
    //     render_in_route: true
    // },
    // "/letters/edit/:letterID": {
    //     show_in_menu: false,
    //     render_in_route: true
    // },
    // "/letters/create": {
    //     show_in_menu: false,
    //     render_in_route: true
    // },
    // "/food/one": {
    //     show_in_menu: true,
    //     render_in_route: true
    // },
    // "/food/one/:id": {
    //     show_in_menu: false,
    //     render_in_route: true
    // },
    // "/food/create": {
    //     show_in_menu: false,
    //     render_in_route: true
    // },
    // "/food/create/:id": {
    //     show_in_menu: false,
    //     render_in_route: true
    // }
}