export default {
    "/check": {
        show_in_menu: false,
        render_in_route: true
    },
    "/children": {
        show_in_menu: true,
        render_in_route: true
    },
    "/children/:id/dashboard": {
        show_in_menu: false,
        render_in_route: true
    },
    "/children/:id/card/:page/:tab": {
        show_in_menu: false,
        render_in_route: true
    },
    "/contracts": {
        show_in_menu: true,
        render_in_route: true
    },
    "/contracts/:id": {
        show_in_menu: false,
        render_in_route: true
    },
    /* "/profiles/1": {
        show_in_menu: false,
        render_in_route: true
    },
    "/profiles/2": {
        show_in_menu: false,
        render_in_route: true
    },
    "/profiles/3": {
        show_in_menu: false,
        render_in_route: true
    },
    "/profiles/4": {
        show_in_menu: false,
        render_in_route: true
    },
    "/profiles/5": {
        show_in_menu: false,
        render_in_route: true
    },
    "/profiles/6": {
        show_in_menu: false,
        render_in_route: true
    },
    "/profiles/7": {
        show_in_menu: false,
        render_in_route: true
    },
    "/profiles/8": {
        show_in_menu: false,
        render_in_route: true
    }, */
}