import React from "react";
import { connect } from "react-redux";
import styles from "./loginStyle";
import Footer from "./layout/Footer/footer";
import { Link } from "react-router-dom";
import { ViewBannersService } from '../config/service';
import ToolTip from 'react-portal-tooltip';
import ModalRecovery from './modal';

//import images
import logo from '../asset/vector_logo_ru.png';
import eyeOpen from '../asset/eye-open.png';
import eyeClose from '../asset/eye-close.png'

/* const CAPTION = `ИННОВАЦИОННАЯ 
ОБРАЗОВАТЕЛЬНАЯ ПЛАТФОРМА`; */
const CAPTIONVECTOR = `ПЛАТФОРМА 
ЦИФРОВОЙ ДИАГНОСТИКИ ПО МКФ`;

const errorText = [
  'Invalid user credentials'
]

class Pictogram extends React.Component {

    state = {
        isTooltipActive: false
    }
    showTooltip = () => {
        this.setState({ isTooltipActive: true })
    }
    hideTooltip = () => {
        this.setState({ isTooltipActive: false })
    }

    openUrl(url) {
        if(url) {
            var win = window.open(url, '_blank'); 
            win.focus();
        }
    }

    render() {
        const { caption, content, id, tooltipImage, tooltipText, url } = this.props;

        const idText = "content" + id;

        return (
            <div id={`${idText}`} onMouseMove={this.showTooltip} onMouseLeave={this.hideTooltip} style={styles.pictogram}>
                <div style={styles.pictogramCaption} onClick={() => this.openUrl(url)}>{caption}</div>
                <div style={styles.pictogramImgContainer}>
                    <img
                        alt="content"
                        onClick={() => this.openUrl(url)}
                        style={styles.pictogramImg}
                        src={this.state.isTooltipActive && tooltipImage ? `${process.env.REACT_APP_API_BASE_URL}/files/${tooltipImage}` : `${process.env.REACT_APP_API_BASE_URL}/files/${content}`}
                    />
                </div>
                <ToolTip active={this.state.isTooltipActive && tooltipText} position="top" arrow="center" parent={`#${idText}`}>
                    <div>
                        {tooltipText && <p>{tooltipText}</p>}
                    </div>
                </ToolTip>
            </div>
        );
    }
}

class PictogramText extends React.Component {

    state = {
        isTooltipActive: false
    }
    showTooltip = () => {
        if (this.props.tooltipText) {
            this.setState({ isTooltipActive: true })
        }
    }
    hideTooltip = () => {
        this.setState({ isTooltipActive: false })
    }

    openUrl(url) {
        if(url) {
            var win = window.open(url, '_blank'); 
            win.focus();
        }
    }

    render() {
        const { caption, content, id, tooltipImage, tooltipText, url } = this.props;

        const idText = "content" + id;

        return (
            <div id={`${idText}`} onMouseMove={this.showTooltip} onMouseLeave={this.hideTooltip} style={styles.pictogram}>
                <div style={styles.pictogramCaption} onClick={() => this.openUrl(url)}>{caption}</div>
                <div style={styles.pictogramImgContainer} onClick={() => this.openUrl(url)}>
                    {content}
                </div>
                <ToolTip active={this.state.isTooltipActive} position="top" arrow="center" parent={`#${idText}`}>
                    <div>
                        {tooltipText && <p>{tooltipText}</p>}
                        {tooltipImage && <img src={`${process.env.REACT_APP_API_BASE_URL}/files/${tooltipImage}`} alt="content" />}
                    </div>
                </ToolTip>
            </div>
        );
    }
}

class PassInput extends React.Component {
    state = {
        showPass: false,
    }

    render() {
        const { showPass } = this.state;
        const { onChange, value } = this.props;
        return (
            <div style={{ height: '100%', position: 'relative' }}>
                <input
                    value={value}
                    id="password"
                    type={showPass ? 'textbox' : 'password'}
                    style={styles.input}
                    onChange={event => {
                        onChange("password", event.target.value);
                    }}
                />
                <div style={{
                    width: 37,
                    height: 37,
                    position: 'absolute',
                    top: 1,
                    right: 4,
                    backgroundImage: showPass ? `url('${eyeOpen}')` : `url('${eyeClose}')`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    cursor: 'pointer',
                }}
                    onClick={() => this.setState({ showPass: !showPass })}
                />
            </div>
        );
    }
}

class Auth extends React.Component {

    state = {
        data: {},
        modalLogic: '',
        //isVectorSite: process.env.REACT_APP_API_BASE_URL.includes('vector.')
    };


    localEmail = () => {
        localStorage.setItem('login', document.querySelector('#login').value);
    };

    async componentDidMount() {
        await ViewBannersService.getList()
        .then(response => (
            this.setState({
                data: response.banners
            })
        ));
    }

    render() {
        const { onSubmit, onChange, auth, values } = this.props;
        //const { isVectorSite } = this.state;

        return (
            <div style={styles.authContainer}>
                <header style={styles.header}>
                    <div style={styles.headerContainer}>
                        <img
                            alt=""
                            style={styles.logo}
                            src={logo}
                        />
                        <span style={styles.caption}>{CAPTIONVECTOR}</span>
                    </div>
                </header>
                <div style={styles.body}>
                    {/* <div style={styles.leftSide} /> */}
                    <div style={styles.gradient} />
                    <form style={styles.form} onSubmit={onSubmit}>
                        <div style={styles.offset} />
                        <div style={styles.welcome}>Добро пожаловать!</div>
                        <div style={styles.loginPassword}>
                            <div style={styles.label}>
                                <label fromhtml="login">Логин</label>
                            </div>
                            <div>
                                <input
                                    value={values.username}
                                    id="login"
                                    type="textbox"
                                    style={styles.input}
                                    onChange={event => onChange("username", event.target.value)}
                                />
                            </div>
                        </div>
                        <div style={styles.loginPassword}>
                            <div style={styles.label}>
                                <label fromhtml="password">Пароль</label>
                            </div>
                            <div>
                                <PassInput onChange={onChange} value={values.password}/>
                            </div>
                            <input style={styles.submit} type="submit" value="ок" />
                            {auth.error !== null && errorText.includes(auth.errorText)
                            && <div style={styles.wrongPass}>Неверный логин или пароль</div>
                            }
                        </div>
                        <Link onClick={this.localEmail} to="/recovery" style={styles.restore}>
                            Восстановить пароль
                        </Link>
                    </form>
                    {/* <div style={styles.rightSide} /> */}
                </div>
                <div style={ styles.pictograms }>
                    <div style={ styles.pictogramsContainer }>
                        { this.state.data.length > 0 && this.state.data.map((item, i) => {
                            if (!item.isBlocked && item.image) {
                                return <Pictogram key={ i } caption={ item.title } content={ item.image }
                                id={ item.id } tooltipImage={ item.tooltipImage } tooltipText={ item.tooltipText }
                                url={ item.url }/>
                            } else if (!item.isBlocked && (item.tooltipText || item.title)) {
                                return <PictogramText key={ i } caption={ item.title } content={ item.tooltipText }
                                id={ item.id } tooltipImage={ item.tooltipImage } tooltipText={ item.tooltipText }
                                url={ item.url }/>
                            }
                            return null
                        }) }
                    </div>
                </div>
                <div style={styles.footer}>
                    <Footer />
                </div>
                <ModalRecovery 
                    modalLogic={this.state.modalLogic}
                    handlerCloseModal={this.handlerCloseModal}
                />
            </div>
        );
    }
}

export default connect(state => state)(Auth);
