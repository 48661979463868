// @flow
import React from 'react'
import './styles.css';
import { ViewService } from '../../../config/service'

class Footer extends React.Component {
    state = {
        data: {}
    };

    async componentDidMount() {
        await ViewService.getItem({ id: 'footer' })
            .then(response => (
                this.setState({
                    data: response.wysiwyg,
                })
            ))

        let div = document.createElement('div');
        div.innerHTML = this.state.data;
        let html = div.textContent;
        this.setState({ html });
    }
    render() {
        return (
            <div id="prev" dangerouslySetInnerHTML={{ __html: this.state.html }} />
        )
    }
}

export default Footer;
