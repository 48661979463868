export const groups = [
    { label: "все", value: 1 },
    { label: "2+", value: 2 },
    { label: "3-4", value: 3 },
    { label: "5", value: 4 },
    { label: "6", value: 5 },
    { label: "нет", value: 6 },
];

export const children = [
    { label: "Замараева Василиса Ивановна", value: 1 },
    { label: "Ребенкова Екатерина Георгиевна", value: 2 },
    { label: "Иванова Мария Васильевна", value: 3 },
    { label: "Милашкина Чуча Георгиевна", value: 4 },
    { label: "Колбасова Салями Колбасовна", value: 5 },
];

export const tableColumns = [{
    Header: '№',
    accessor: 'index'
}, {
    Header: 'дата проведения',
    accessor: 'date',
    width: 140
}, {
    Header: 'возраст на момент диагностики',
    accessor: 'age',
    width: 100
}, {
    Header: 'заключение специалиста',
    accessor: 'expertOpinion',
    width: 100
}, {
    Header: 'возрастная норма',
    accessor: 'ageNorm',
    width: 90
}, {
    Header: 'показать на графике',
    accessor: 'showOnChart',
    width: 90
}];

export const personTableColumns = [{
    Header: '№',
    accessor: 'index'
},{
    Header: 'ФИО реабилитанта',
    accessor: 'fio',
}, {
    Header: 'Класс',
    accessor: 'classes',
}, 
{
    Header: 'Дата рождения',
    accessor: 'birthDate',
},
{
    Header: 'Группа',
    accessor: 'groupTitle',
},
{
    Header: 'Даты диагностики',
    accessor: 'startDate',
},
{
    Header: 'Специалист',
    accessor: 'specialist',
},
{
    Header: 'Заключение специалиста',
    accessor: 'specResult',
}, {
    style: {
        border: 'none',
        backgroundColor: '#fff',
    },
    hideHeader: true,
    disableSort: true,
    accessor: 'deleteButton',
},];

export const personTableColumnsDelete = [{
    Header: '№',
    accessor: 'index'
}, {
    Header: 'ФИО реабилитанта',
    accessor: 'fio',
}, {
    Header: 'Класс',
    accessor: 'classes',
}, 
{
    Header: 'Дата рождения',
    accessor: 'birthDate',
},
{
    Header: 'Группа',
    accessor: 'groupTitle',
},
{
    Header: 'Даты диагностики',
    accessor: 'startDate',
},
{
    Header: 'Специалист',
    accessor: 'specialist',
},
{
    Header: 'Заключение специалиста',
    accessor: 'specResult',
}];

export const diagnosticLines = [
    { label: 1, psychoLabel: "1. Восприятие", key: 'perception' },
    { label: 2, psychoLabel: "2. Мышление", key: 'thinking' },
    { label: 3, psychoLabel: "3. Память", key: 'memory' },
    { label: 4, psychoLabel: "4. Внимание", key: 'attention' },
    { label: 5, psychoLabel: "5. Воображение", key: 'imagination' },
    { label: 6, psychoLabel: "6. Общая осведомленность", key: 'generalAwareness' },
    { label: 7, psychoLabel: "7. Общая речь", key: 'generalSpeech' },
    { label: 8, psychoLabel: "8. Лексика, словарь, связная речь", key: 'vocabularyDictionaryConnectedSpeech' },
    { label: 9, psychoLabel: "9. Грамматические компетенции", key: 'grammaticalCompetences' },
    { label: 10, psychoLabel: "10. Фонетико-фонологические компетенции", key: 'PhoneticPhonologicalCompetencies' },
    { label: 11, psychoLabel: "11. Общение", key: 'communication' },
    { label: 12, psychoLabel: "12. Игра", key: 'game' },
    { label: 13, psychoLabel: "13. Тонкая моторика", key: 'fineMotorSkills' },
    { label: 14, psychoLabel: "14. Общая моторика", key: 'commonMotorSkills' },
    { label: 15, psychoLabel: "15. Эмоциональный интеллект", key: 'emotionalIntelligence' },
    { label: 16, psychoLabel: "16. Социально-бытовые навыки", key: 'socialSkills' },
    { label: 17, psychoLabel: "17. Произвольность", key: 'arbitrariness' },
    { label: 18, psychoLabel: "18. Учебная мотивация", key: 'learningMotivation' },
    { label: 19, psychoLabel: "19. Учебная мотивация", key: 'learningMotivation1' },
    { label: 20, psychoLabel: "20. Учебная мотивация", key: 'learningMotivation2' },
    { label: 21, psychoLabel: "21. Учебная мотивация", key: 'learningMotivation3' },
    { label: 22, psychoLabel: "22. Учебная мотивация", key: 'learningMotivation4' },
    { label: 23, psychoLabel: "23. Учебная мотивация", key: 'learningMotivation5' },
    { label: 24, psychoLabel: "24. Учебная мотивация", key: 'learningMotivation6' },
];

export const profiles = [
    {
        id: '1',
        title: 'Медицинские показатели и АФК',
        shortTitle: 'МПиАФК',
        name: 'profileOne',
        sectionId: 20,
    },
    {
        id: '2',
        title: 'Социально-психологическая реабилитация',
        shortTitle: 'СПСР',
        name: 'profileTwo',
        sectionId: 21,
    },
    {
        id: '3',
        title: 'Социально-педагогическая реабилитация',
        shortTitle: 'СПР',
        name: 'profileThree',
        sectionId: 22,
    },
    {
        id: '4',
        title: 'Социокультурная реабилитация',
        shortTitle: 'СКР',
        name: 'profileFour',
        sectionId: 23,
    },
    {
        id: '5',
        title: 'Социально-бытовая реабилитация',
        shortTitle: 'СБР',
        name: 'profileFive',
        sectionId: 24,
    },
    {
        id: '6',
        title: 'Социально-средовая реабилитация',
        shortTitle: 'ССР',
        name: 'profileSix',
        sectionId: 25,
    },
    {
        id: '7',
        title: 'Профиль 7',
        shortTitle: 'П7',
        name: 'profileSeven',
        sectionId: 26,
    },
    {
        id: '8',
        title: 'Профиль 8',
        shortTitle: 'П8',
        name: 'profileEight',
        sectionId: 27,
    },
]
